<template>
  <span>
    <b-row class="mt-4 coverLetterArea">
      <ckeditor :value="coverLetter" :config="config"></ckeditor>
    </b-row>
    <b-row class="mt-4 mb-4">
      <b-dropdown
        :click="trackDownloadIntent"
        :no-flip="true"
        id="dropdown-1"
        text="Download Cover Letter as"
      >
        <b-dropdown-item @click="onDownloadAs('txt')"
          >Plain Text</b-dropdown-item
        >
        <b-dropdown-item @click="onDownloadAs('pdf')">PDF</b-dropdown-item>
        <b-dropdown-item @click="onDownloadAs('docx')"
          >Word Document</b-dropdown-item
        >
        <b-dropdown-item @click="onDownloadAs('gdoc')"
          >Google Docs</b-dropdown-item
        >
      </b-dropdown>
    </b-row>
  </span>
</template>

<script>
import { Analytics } from "@aws-amplify/analytics";
import CLDownloader from "@/utils/CLDownloader";

export default {
  name: "CoverLetterHolder",
  components: {},
  props: ["completionText"],
  data() {
    return {
      coverLetter: null,
      plainText: null,
      config: {
        // increase the height of the editor
        height: '19em'
      }
    };
  },
  beforeMount() {
    let that = this;
    this.$root.$on("show-cover-letter", function (event) {
      that.plainText = event;
      that.coverLetter = that.sanitizeCompletionText(that.plainText);
    });
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      console.log("Dropdown is about to be shown", bvEvent);
      Analytics.record({ name: "showDownloadAsBtn" });
    });
  },
  methods: {
    sanitizeCompletionText(text) {
      const dataArray = text.split("\n");
      return dataArray
        .filter((paragraph) => paragraph.length > 0)
        .map((paragraph) => `<p>${paragraph}</p>`)
        .join("");
    },
    onDownloadAs(downloadType) {
      console.log(downloadType, " hello world");
      Analytics.record({
        name: "downloadCoverLetterAs",
        attributes: { downloadType },
      });
      if (!this.coverLetter) {
        console.log("nothing can be done for the truth");
      } else {
        CLDownloader.download(
          this.plainText,
          "cover-letter-genius",
          downloadType
        );
      }
    },
    trackDownloadIntent() {
      console.log("tracking download intent");
      Analytics.record({ name: "showDownloadAsBtn" });
    },
  },
};
</script>

<style>
.coverLetterArea {
  position: relative;
}
</style>
