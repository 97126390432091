<template>
    <b-row class="mt-2" ref="NPSComponent">
        <button data-tf-slider="BJZiJibR" 
        data-tf-position="right" 
        data-tf-opacity="100" 
        data-tf-iframe-props="title=Cover Letter Genius Net Promoter Score®" 
        data-tf-transitive-search-params 
        data-tf-medium="snippet" 
        style="all:unset;font-family:Helvetica,Arial,sans-serif;display:inline-block;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;background-color:#9E32A8;color:#fff;font-size:20px;border-radius:12px;padding:0 33px;font-weight:bold;height:50px;cursor:pointer;line-height:50px;text-align:center;margin:0;text-decoration:none;"
        @click="openForm">
            Feedback</button>
    </b-row>
</template>

<script>

const loadTypeform = async () => {
  // sorry I am not familiar with Vue and don't know how to load external scripts and styles :)
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.setAttribute('src', '//embed.typeform.com/next/embed.js')
    script.onload = resolve
    document.head.appendChild(script);

    const cssLink = document.createElement("link");
    cssLink.setAttribute('rel', 'stylesheet')
    cssLink.setAttribute('href', '//embed.typeform.com/next/css/slider.css')
    document.head.appendChild(cssLink);
  })
}

const openTypeform = () => {
  window.tf.createSlider('BJZiJibR').open()
}

export default {
    name: "NPSSurveyComponent",
    components: {},
    props: ['displayValue'],
    async mounted() {
    },
     data() {
        return {}
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        async openForm(event) {
            await loadTypeform()
            openTypeform()
        }
    }
}
</script>

<style >
</style>
