import { API, graphqlOperation } from 'aws-amplify';
import {createPrompt, createSubcriptions, updateSubcriptions} from '@/graphql/mutations'
import { listSubcriptionss } from '@/graphql/queries'


const data = {
    async saveUserPrompt(userPromptObject) {
        await API.graphql(graphqlOperation(
            createPrompt, {
                input: {
                    userBio: userPromptObject['userBio'],
                    jobDescription: userPromptObject['jobDescription'],
                    ownerEmail: userPromptObject['ownerEmail'],
                    generatedCoverLetter: userPromptObject['generatedCoverLetter'],
                    guid: userPromptObject['guid']
                }
            })
        );
    },

    async addSubscription(userSubscription) {
        await API.graphql(graphqlOperation(
            createSubcriptions, {
                input: {
                    user: userSubscription['ownerEmail'],
                    tier: userSubscription['subcriptionTier'],
                    usageCounter: userSubscription['counter']
                }
            }
        ))
    },

    async updateUserSubscription(usageInfo) {
        return await API.graphql(graphqlOperation(
            updateSubcriptions, { 
                input: {
                    id: usageInfo['id'],
                    user: usageInfo['user'],
                    tier: usageInfo['tier'],
                    usageCounter: usageInfo['usageCounter']
                }
            }
        ))
    },

    async getUserSubscriptionInfo(user) {
        return await API.graphql(graphqlOperation(
            listSubcriptionss, { variables: { user } }
        ))
    }

}

export default data
