import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
// import { Document, Packer, Paragraph } from 'docx'
// const officegen = require('officegen');
// const fs = require('fs');

const CLDownloader = {
    download(body, fileName, extension) {
        let fileEncoders = {
            pdf: 'application/pdf',
            doc: 'application/msword;charset=utf-8',
            gdocs: 'application/vnd.google-apps.document;charset=utf-8',
            text: 'text/plain;charset=utf-8'
        }
        let fileEncoding = fileEncoders[extension]
        let cleanedText = body.replace(/<p>/g, '').replace(/<\/p>/g, ' ')
        console.log(cleanedText)
        if (extension === 'pdf') {
            const pdf = new jsPDF()
            const lines = pdf.splitTextToSize(cleanedText, 180) // 180 is the width of the text area
            pdf.text(10, 10, lines)
            pdf.save('cover-letter-genius.pdf')
            return
        // TODO: Fix docx export, cannot be opene by Word files
        // } if (extension === 'doc') {
        //     const doc = new Document()
        //     const paragraph = new Paragraph(cleanedText)
        //     doc.addParagraph(paragraph)
        //     Packer.toBlob(doc).then(blob => {
        //         console.log(blob)
        //         FileSaver.saveAs(blob, `${fileName}.${extension}`)
        //     })
        //     return
        }
        else {
            let blob = new Blob([cleanedText], {type: fileEncoding})
            FileSaver.saveAs(blob, `${fileName}.${extension}`)
        }
    },

    async getCVTextFromPdf(dataUrl) {
        const pdf = await window.pdfjsLib.getDocument(dataUrl).promise;
        const maxPages = pdf.numPages;
        let textContent = [];

        for (let i = 1; i <= maxPages; i++) {
            const page = await pdf.getPage(i);
            const content = await page.getTextContent();
            const pageTextContent = content.items.map(item => item.str).join('');
            textContent.push(pageTextContent);
        }

        return textContent.join('\n');
    }
}


export default CLDownloader
