<template>
    <b-container style="text-align: left">
        <b-row>
            <b-col>
                <h2>Terms of Service for Cover Letter Genius by Brecht AI</h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <p>Welcome to Cover Letter Genius, a web application that generates personalized, job-specific, and well-written cover letters in seconds. Please read these Terms of Service carefully as they govern your use of the website and any services offered through it. By using Cover Letter Genius, you agree to be bound by these terms and conditions.</p>
                <ol class="ordered-list">
                    <li>
                        <h5>Acceptance of Terms </h5>
                        <p>By accessing or using Cover Letter Genius, you agree to these terms and conditions. If you do not agree to these terms and conditions, please do not use the website. Brecht AI reserves the right to modify these terms at any time without prior notice. Your continued use of the website following any modifications constitutes your acceptance of those modifications.</p>
                    </li>
                    <li>
                        <h5>Use of the Service</h5>
                        <p>Cover Letter Genius is a tool that generates cover letters using data input from the user. By using the service, you agree to provide accurate and complete information about yourself and the job you are applying to. Brecht AI is not responsible for any inaccuracies or errors in the information you provide.</p>
                    </li>
                    <li>
                        <h5>Legal Use</h5>
                        <p>You agree not to use Cover Letter Genius for any illegal or unauthorized purpose. You may not use the service to violate any applicable laws, rules, or regulations.</p>
                    </li>
                    <li>
                        <h5>Intellectual Property</h5>
                        <p>Brecht AI owns all intellectual property rights in Cover Letter Genius, including but not limited to the software, algorithms, design, and user interface. You may not copy, modify, distribute, or create derivative works based on the website or any part of it.</p>
                    </li>    
                    <li>
                        <h5>Disclaimer of Warranties</h5>
                        <p>Cover Letter Genius is provided "as is" and Brecht AI makes no warranties or representations, express or implied, about the website or the accuracy, reliability, completeness, suitability, or availability of the information, products, services, or related graphics contained on the website for any purpose.</p>
                    </li>
                    <li>
                        <h5>Limitation of Liability</h5>
                        <p>Brecht AI will not be liable for any damages of any kind arising from the use of Cover Letter Genius, including but not limited to direct, indirect, incidental, punitive, and consequential damages. This includes any errors or omissions in the content of the website or any loss or damage of any kind incurred as a result of the use of the website.</p>
                    </li>    
                    <li>
                        <h5>Indemnification</h5>
                        <p>You agree to indemnify and hold harmless Brecht AI and its affiliates, officers, agents, and employees from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the website, your violation of these terms and conditions, or your violation of any rights of another.</p>
                    </li>
                    <li>
                        <h5>Governing Law</h5>
                        <p>These terms and conditions are governed by and construed in accordance with the laws of the State of California, without giving effect to any principles of conflicts of law.</p>
                    </li>    
                    <li>
                        <h5>Dispute Resolution</h5>
                        <p>Any disputes arising out of or related to these terms and conditions or the use of Cover Letter Genius shall be resolved by binding arbitration in accordance with the rules of the German Arbitration Institution. The arbitration shall take place in Berlin, Germany.</p>
                    </li>
                    <li>
                        <h5>Entire Agreement</h5>
                        <p>These terms and conditions constitute the entire agreement between you and Brecht AI and govern your use of Cover Letter Genius. These terms and conditions supersede any prior agreements or understandings, whether written or oral, between you and Brecht AI.</p>
                    </li>    
                    <li>
                        <h5>Contact Information</h5>
                        <p>If you have any questions or comments about these terms and conditions, please contact Brecht AI at info@brecht.ai.</p>
                    </li>

                </ol>
                <p>By using Cover Letter Genius, you agree to these terms and conditions. If you do not agree to these terms and conditions, please do not use the website.</p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "TermsOfServicePage",
    data() {
        return {
            pageElements: [

            ]
        }
    },
    components: {},
}
</script>

<style scoped>

</style>