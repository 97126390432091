import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import router from "./router";
import { Amplify } from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import { Analytics } from "@aws-amplify/analytics";
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
import awsconfig from "./aws-exports";
import store from "./store/";
import CKEditor from "ckeditor4-vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(CKEditor);
// Init plugin
Vue.use(Loading);

Vue.config.productionTip = false;
Amplify.configure(awsconfig);
Amplify.addPluggable(new AmazonAIPredictionsProvider());
Auth.configure(awsconfig);
Analytics.configure(awsconfig);

Analytics.autoTrack("pageView", {
  enabled: true,
  provider: "AWSPinpoint",
  eventName: "pageView",
  type: "SPA",
  getUrl: () => {
    return window.location.origin + window.location.pathname;
  },
});

window?.google?.accounts?.id?.renderButton(
  document.getElementById("google-login-btn"),
  { theme: "outline", size: "large" } // customization attributes
);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
