import { Analytics } from "@aws-amplify/analytics";
import { Predictions } from '@aws-amplify/predictions';

const analyticsHelper = {
    async updateUserAddress(emailAddress) {
        await Analytics.updateEndpoint({
            address: emailAddress,
            userId: emailAddress,
            channelType: 'EMAIL',
            optOut: 'NONE',
        }).then(() => {})
    },

    async updateUserInterest(emailAddress, userInterests) {
        await Analytics.updateEndpoint({
            attributes: {
                address: emailAddress,
                skills: userInterests
            },
            userAttributes: {
                interests: userInterests
            }
        }).then(() => {})
    },

    async updateUserAnalyticsOptin(emailAddress, optStatus) {
        await Analytics.updateEndpoint({
            address: emailAddress,
            optOut: optStatus || 'NONE',
        }).then(() => {})
    },

    async detectUserSkills(text) {
        let payload = await Predictions.interpret(
            {
                text: {
                    source: {
                        text: text,
                    },
                    type: "ENTITY"
                }
            }
        )
        console.log(payload)
    },

    async extractTextFromCV(file) {
        try {
            let response = await Predictions.identify({
                text: {
                    source: {
                        file
                    }
                }
            })
            console.log(response)
        } catch (e) {
            console.log(e)
        }
    }
}

export default analyticsHelper
