<template>
    <span>
        <img class="rounded-circle shadow-1-strong mb-4"
             v-bind:src="img" :alt="name"
             style="width: 150px;" />
        <div class="row d-flex justify-content-center">
            <div class="col-lg-8">
                <h5 class="mb-3">{{ name }}</h5>
                <!--            <p>{{jobRole}}</p>-->
                <p class="text-muted">
                    <i class="fas fa-quote-left pe-2"></i>
                    {{ testimonial }}
                </p>
            </div>
        </div>
    </span>
</template>

<script>
export default {
    name: "CustomerTestimonial",
    props: ['name', 'img', 'testimonial', 'jobRole']
}
</script>

<style scoped>

</style>
