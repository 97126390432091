<template>
    <b-container>
        <b-row align-h="center">
            <b-col cols="5" align-self="center">
                <div>
                    <span class="mb-3">
                        <h1>Exercise Your Data Subjects Rights</h1>
                    </span>
                    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                        <b-form-group
                            id="input-group-1"
                            label="Email address:"
                            label-for="input-1"
                            description="We'll never share your email with anyone else."
                        >
                            <b-form-input
                                id="input-1"
                                v-model="form.email"
                                type="email"
                                placeholder="Enter email"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
                            <b-form-input
                                id="input-2"
                                v-model="form.name"
                                placeholder="Enter name"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-3" class="mt-3" label="User Request Type:" label-for="input-3">
                            <b-form-select
                                style="margin-right: 100%"
                                id="input-3"
                                v-model="form.requestType"
                                :options="requestTypes"
                                required
                                size="lg"
                            ></b-form-select>
                        </b-form-group>

                        <b-button type="submit" class="m-2" variant="primary">Submit</b-button>
                        <b-button type="reset" variant="danger">Reset</b-button>
                    </b-form>
                </div>
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
export default {
    name: "GDPRRequestPage",
    data() {
        return {
            form: {
                name: null,
                email: null,
                requestType: null
            },
            show: true,
            requestTypes: [{ text: 'Select One', value: null }, 'Deletion Request', 'Information Request']
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            this.form.food = null
            this.form.checked = []
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    }
}
</script>

<style scoped>

</style>
