<template>
    <b-container style="text-align: left">
        <b-row>
            <b-col>
                <h2>PRIVACY POLICY</h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from <a href="/">Brecht.ai</a>.</p>
                <h5 class="text-heading title_color">WHAT PERSONAL INFORMATION WE COLLECT</h5>
                <p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device.</p>
                <p>Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically collected information as <strong>Device Information</strong>.</p>
                <p>We collect Device Information using the following technologies:</p>
                <ul class="unordered-list">
                    <li>
                        <p><strong>Cookies</strong> are data files that are placed on your device or computer and often include an anonymous unique identifier.</p>
                    </li>
                    <li>
                        <p><strong>Log files</strong> track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
                    </li>
                    <li>
                        <p><strong>User Data</strong> are data that the user inputs into our application and the corresponding results. As part of our service, we collect and store user input data, and the corresponding results, in order to improve our product and provide a better user experience. This data may include, but is not limited to, information entered by users into our website or mobile application. </p>
                    </li>

                </ul>
                <p>By Personal Information in this Privacy Policy, we are talking both about Device Information and Order Information.</p>
                <h5 class="text-heading title_color">HOW DO WE USE YOUR PERSONAL INFORMATION</h5>
                <p>We use the Order Information that we collect generally to fulfil any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).</p>
                <p>Additionally, we use this Order Information to: - Communicate with you. - Screen our orders for potential risk or fraud. - When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</p>
                <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site.</p>

                <h5 class="text-heading title_color">SHARING YOUR PERSONAL INFORMATION</h5>
                <p>We share your Personal Information with third parties to help us use your Personal Information, as described above.</p>
                <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful requests for information we receive, or to otherwise protect our rights.</p>
                <h5 class="text-heading title_color">BEHAVIOURAL ADVERTISING</h5>
                <p>We use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.</p>

                <h5 class="text-heading title_color">YOUR RIGHTS</h5>
                <p>If you are a European resident, you have the right to access the personal information we hold about you and to ask that your personal information is corrected, updated, or deleted. If you would like to exercise this right, please contact us.</p>
                <p>Additionally, if you are a European resident we note that we are processing your information in order to fulfil contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.</p>

                <h5 class="text-heading title_color">DATA RETENTION</h5>
                <p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>

                <h5 class="text-heading title_color">CHANGES</h5>
                <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
                <p>If you have questions and/or require more information, do not hesitate to contact us <a href="mailto:info@Brecht.ai">info@Brecht.ai</a>.</p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "PrivacyPage",
    data() {
        return {
            pageElements: [

            ]
        }
    },
    components: {},
}
</script>

<style scoped>

</style>
