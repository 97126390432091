<template>
    <b-row>
        <stripe-checkout
            ref="checkoutRef"
            mode="subscription"
            :pk="stripePublicKey"
            :line-items="[{price: billedPriceId, quantity: 1,}]"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="v => loading = v"
        />
        <b-col v-for="(option, index) in options" :key="index">
            <b-card :title="option.name">
                <template #header>
                    <h1 class="mb-0">${{ option.price }}</h1> <h6>/month</h6>
                    <b-badge :variant="option.htmlBadge.color">{{ option.htmlBadge.text }}</b-badge>
                </template>
                <b-card-body>
                    <ul style="text-align: left">
                        <li v-for="(feature, index) in option.features" :key="index">{{ feature }}</li>
                    </ul>
                    <button class="btn btn-primary" @click="makePayment(option)">
                        {{option.buttonText}}
                    </button>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { Analytics } from "@aws-amplify/analytics";

export default {
    name: "SubscriptionsComponent",
    components: {
        StripeCheckout,
    },
    mounted() {
        this.startTime = new Date()
        if (window.location.host === 'www.brecht.ai') {
            this.env = 'prod'
            this.stripePublicKey = 'pk_live_40MrUX2RAEZfJsKCMXQVuvie'
        } else {
            this.env = 'test'
            this.stripePublicKey = 'pk_test_4hF0ix9UYiRBbygFHQBN4p7O'
        }
    },
    data() {
        return {
            startTime: null,
            env: null,
            stripePublicKey: 'pk_test_4hF0ix9UYiRBbygFHQBN4p7O',
            loading: false,
            successURL: 'http://localhost:8080/payment/success',
            cancelURL: 'http://localhost:8080/payment/failed',
            billedPriceId: null,
            options: [
                {
                    name: 'Free',
                    price: 0,
                    features: [
                        '3 personalized cover letters per month',
                        'Download as text, PDF, and Google Docs',
                    ],
                    image: 'OPTION_1_IMAGE_URL',
                    buttonText: 'Start as free user',
                    htmlBadge: {
                        text: 'STARTER',
                        color: 'primary'
                    }
                },
                {
                    name: 'Professional',
                    price: '9.99',
                    features: [
                        'Unlimited personalized cover letters per month',
                        '3 cover letter versions per request',
                        'Download as text, PDF, and Google Docs',
                    ],
                    image: 'OPTION_2_IMAGE_URL',
                    buttonText: 'Monthly billing',
                    priceId: {
                        test: 'price_1MfMtjJRQdfR2bJ6kx5kVSoK',
                        prod: 'price_1MkDRDJRQdfR2bJ6fco6OEY3'
                    },
                    htmlBadge: {
                        text: 'RECOMMENDED',
                        color: 'success'
                    }
                },
                {
                    name: 'Gold',
                    price: '14.99',
                    features: [
                        'Unlimited personalized cover letters per month',
                        '5 cover letter versions per request',
                        'Cover Letter rank by human recruiter',
                        'Unlimited auto job matcher',
                        'Download as text, PDF, and Google Docs',
                    ],
                    image: 'OPTION_3_IMAGE_URL',
                    buttonText: 'Monthly billing',
                    priceId: {
                        test: 'price_1MfMuZJRQdfR2bJ6tPI2Uani',
                        prod: 'price_1MkDRDJRQdfR2bJ6WjnFukQI'
                    },
                    htmlBadge: {
                        text: 'GOLD',
                        color: 'warning'
                    }
                },
            ],
            locale: 'auto',
            zipCode: true,
        };
    },
    methods: {
        setStripeReturnUrl(tier) {
            if (window.location.host === 'www.brecht.ai') {
                this.successURL = 'https://brecht.ai/payment/success'
                this.cancelURL = 'https://brecht.ai/payment/failed'
            } else {
                this.successURL = 'http://localhost:8080/payment/success'
                this.cancelURL = 'http://localhost:8080/payment/failed'
            }
            this.successURL = `${this.successURL}/${tier}`
            this.cancelURL = `${this.cancelURL}/${tier}`
        },
        makePayment(option){
            let endTime = new Date()
            const waitTimeInMins = ((endTime.getTime() - this.startTime.getTime()) / 60000)
             Analytics.record({
                name: 'MakeSubscriptionIntent',
                attributes: {
                    plan: option.name,
                    price: option.price
                },
                 metrics: { minutesBeforeIntent: waitTimeInMins }
            })
            if (option.name === "Free") {
                this.$router.push({name: 'App'})
            } else {
                this.billedPriceId = option.priceId[this.env]
                console.log(this.billedPriceId)
                let subscriptionTier = option.name.toLowerCase()
                this.setStripeReturnUrl(subscriptionTier)
                this.$refs.checkoutRef.redirectToCheckout();
            }
        },
    },
};
</script>

<style>
.badge-success {
    background-color: #28a745
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

.badge-primary {
    color: #fff;
    background-color: #007bff;
}
</style>
