/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPrompt = /* GraphQL */ `
  mutation CreatePrompt(
    $input: CreatePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    createPrompt(input: $input, condition: $condition) {
      id
      userBio
      jobDescription
      ownerEmail
      guid
      ownerId
      owner
      timestamp
      generatedCoverLetter
      createdAt
      updatedAt
    }
  }
`;
export const updatePrompt = /* GraphQL */ `
  mutation UpdatePrompt(
    $input: UpdatePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    updatePrompt(input: $input, condition: $condition) {
      id
      userBio
      jobDescription
      ownerEmail
      guid
      ownerId
      owner
      timestamp
      generatedCoverLetter
      createdAt
      updatedAt
    }
  }
`;
export const deletePrompt = /* GraphQL */ `
  mutation DeletePrompt(
    $input: DeletePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    deletePrompt(input: $input, condition: $condition) {
      id
      userBio
      jobDescription
      ownerEmail
      guid
      ownerId
      owner
      timestamp
      generatedCoverLetter
      createdAt
      updatedAt
    }
  }
`;
export const createSubcriptions = /* GraphQL */ `
  mutation CreateSubcriptions(
    $input: CreateSubcriptionsInput!
    $condition: ModelSubcriptionsConditionInput
  ) {
    createSubcriptions(input: $input, condition: $condition) {
      id
      user
      tier
      usageCounter
      trID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSubcriptions = /* GraphQL */ `
  mutation UpdateSubcriptions(
    $input: UpdateSubcriptionsInput!
    $condition: ModelSubcriptionsConditionInput
  ) {
    updateSubcriptions(input: $input, condition: $condition) {
      id
      user
      tier
      usageCounter
      trID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSubcriptions = /* GraphQL */ `
  mutation DeleteSubcriptions(
    $input: DeleteSubcriptionsInput!
    $condition: ModelSubcriptionsConditionInput
  ) {
    deleteSubcriptions(input: $input, condition: $condition) {
      id
      user
      tier
      usageCounter
      trID
      createdAt
      updatedAt
      owner
    }
  }
`;
