<template>
    <b-modal
        id="myModal"
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
        v-model="showPrompt"
        hide-footer
        centered
    >
        <template #modal-title>
            <h3><strong>Welcome to Brecht.AI</strong></h3>
        </template>
        <div>
            <div>
                <p>
                    I declare that I accept the <router-link to="/tos" target="_blank">terms and conditions</router-link> and that I have read the
                    <router-link to="/privacy" target="_blank">privacy policy</router-link> of the AI Assistant portal.
                    I also agree to the processing of my data described there.
                </p>
            </div>
            <div>
                <b-button @click="acceptTos(true)" variant="success">Agree and Continue</b-button>
                <b-button @click="acceptTos(false)" variant="outline-secondary">Cancel</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { Analytics } from "@aws-amplify/analytics";
import AnalyticsHelper from "@/utils/AnalyticsHelper";
import { Cache } from "aws-amplify";

import { Auth } from "@aws-amplify/auth";

export default {
    name: "AcceptTosModalComponent",
    props: ['showM'],
    mounted: async function () {
        // Cache.removeItem('BrechtUserToS')
        let currentUser = await Auth.currentUserInfo()
        if (currentUser.attributes !== undefined) {
            this.email = currentUser.attributes.email
        } else {
            this.email = currentUser.email
        }
        let acceptToS = Cache.getItem('BrechtUserToS')
        this.showPrompt = !acceptToS;
    },
    data() {
        return {
            showPrompt: null,
            email: null
        }
    },
    methods: {
        async acceptTos(status){
            console.log('user accepted tos', status, this.email)
            await Analytics.record({
                name: 'AcceptToS',
                attributes: {
                    status: status
                }
            })
            if (status) {
                Cache.setItem('BrechtUserToS', status);
                this.showPrompt = false
                await AnalyticsHelper.updateUserAddress(this.email)
            } else {
                // initiate a redirect here to home
                await this.$router.push({name: 'Landing'})
            }
        }
    }
}
</script>

<style scoped>
button {
    margin-right: 10px;
}
</style>
