<template>
    <b-container class="card-signin">
        <div class="px-4 border shadow">
            <b-row >
                <b-col>
                    <img src="../assets/logo-50.png" fluid alt="Brecht AI Logo" />
                    <h6>Welcome to Brecht AI</h6>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p>Login with your social accounts to continue</p>
                    <div>
                        <b-row class="mb-2">
                            <span>
                                <b-button variant="outline-info" href="/login/email"  >
                                    <b-icon-envelope-fill></b-icon-envelope-fill>  Sign in with Email
                                </b-button>
                            </span>
                        </b-row>
                        <b-row class="mt-2" v-show="false">
                            <!-- <SignInWithGoogle/> -->
                        </b-row>
                        <b-row class="mt-2" v-show="false">
                            <SignInWithFacebook/>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import { mapActions } from "vuex"
// import SignInWithGoogle from "@/components/SignInWithGoogle";
import SignInWithFacebook from "@/components/SignInWithFacebook";

export default {
    name: "LoginPage",
    components: {
        // SignInWithGoogle, //
        SignInWithFacebook,
    },
    data() {
        return {
            googleSignInParams: {
                client_id:
                    "675614778355-bi775n5d0te51p5mtns6r4snl74fsq2k.apps.googleusercontent.com"
            },
            user: null
        }
    },
    methods: {
        ...mapActions(["login", "logout"]),
        redirect: function() {
            this.$router.push({name: 'App'});
        },
    }
}
</script>

<style scoped>
.card-signin {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.card-signin > button {

}


</style>
