<template>
  <b-container>
    <b-row class="gap-5">
      <b-col md="5" class="px-4 border shadow textLeft">
        <b-row class="mt-4">
          <b-col>
            <h1>Cover Letter Genius by Brecht AI</h1>
            <h6 class="mt-3 subHeading">
              Generate personalized, well-written, and job specific cover
              letters in seconds to become a standout candidate.
            </h6>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <CoverLetterUserInputs />
        </b-row>
        <h6 class="mt-3 subHeading" align="right">
              Hold tight! Generating a cover letter can take up to 30 seconds...
        </h6>
      </b-col>
      <b-col md="5" ref="coverLetterArea" class="px-4 border shadow textLeft">
        <b-row class="mt-4">
          <b-col>
            <h2>Access and edit your personalized cover letter below</h2>
          </b-col>
        </b-row>
        <CoverLetterHolder :completion-text="coverLetter" />
        <NPSSurveyComponent v-show="showNPS" />
        <AcceptTosModalComponent/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CoverLetterHolder from "@/components/CoverLetterHolder";
import CoverLetterUserInputs from "@/components/CoverLetterUserInputs";
import NPSSurveyComponent from "@/components/NPSSurveyComponent";
import AcceptTosModalComponent from "@/components/AcceptTosModalComponent";

export default {
  name: "HomePage",
  components: {
    CoverLetterHolder,
    CoverLetterUserInputs,
    NPSSurveyComponent,
    AcceptTosModalComponent,
  },
  beforeMount() {
    let that = this;
    this.$root.$on("show-nps-survey", function (event) {
      console.log("show-nps-survey", event);
      that.showNPS = true;
    });
  },
  async mounted() {
      // show modal asking user consent to emails
      // then update pinpoint endpoint with address
  },
  data() {
    return {
      show: true,
      coverLetter: "",
      showNPS: false,
        toSPrompt: true
    };
  },
  methods: {
  },
};
</script>

<style>
.layout {
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 50px;
  margin: 24px;
}
/*.layout > div {*/
/*    max-width: 360px;*/
/*}*/
@media (min-width: 600px) {
  .layout {
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 60px;
  }
}
.form-group::before,
.form-group::after {
  margin: 2em;
}
label {
  text-align: left !important;
}
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
  height: 100%;
}

.border {
  border: solid 1px lightgrey;
  border-radius: 10px;
}

.shadow {
  box-shadow: 2px 2px 2px whitesmoke;
}

.subHeading {
  font-size: 12px;
  color: lightslategray;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

@media only screen and (max-width: 600px) {
  .vr {
    transform: rotate(90deg);
    margin: 10px 0px 10px 0px;
  }
}
</style>
