<template>
  <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group label="Upload your CV:" >
          <b-form-file
              v-model="userCV"
              :state="Boolean(userCV)"
              class="mt-3"
              accept=".pdf, .doc, .docx"
              @input="onCVUpload"
              plain
          >
          </b-form-file>
          <div class="mt-3">Selected file: {{ userCV ? userCV.name : '' }}</div>
      </b-form-group>
    <b-form-group
      id="input-group-2"
      label="Enter a few words about yourself:"
      label-for="input-2"
      class="mt-3"
    >
      <b-form-textarea
        id="textarea"
        v-model="form.userBio"
        required
        placeholder="You can copy and paste directly from your CV or LinkedIn, or share a few words about yourself. Don't worry about formatting or grammar ..."
        rows="6"
        @change="onEditFormInput('userBio')"
        max-rows="6"
        class="mt-2"
      ></b-form-textarea>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Enter the job description:"
      label-for="input-1"
      class="mt-3"
    >
      <b-form-textarea
        id="textarea"
        required
        v-model="form.jobDescription"
        placeholder="Copy and paste the job description here ..."
        rows="6"
        @change="onEditFormInput('jobDescription')"
        max-rows="6"
        class="mt-2"
      ></b-form-textarea>
    </b-form-group>
    <b-form-group id="input-group-3" label-for="input-1" class="mt-3 ml-3">
      <b-form-checkbox
        id="checkbox-1"
        v-model="form.rememberBio"
        name="checkbox-1"
        value="true"
        @change="onRememberUserBio"
        unchecked-value="false"
        class="checkboxRemember"
      >
        Remember my bio
      </b-form-checkbox>
    </b-form-group>
    <b-form-group class="mt-3 mb-3 d-flex justify-content-end">
      <b-button
        type="submit"
        :style="{ marginRight: '1em' }"
        size="lg"
        class="button buttonSuccess"
        >Generate</b-button>
      <b-button type="reset" size="lg" class="button">Reset</b-button>
    </b-form-group>
  </b-form>
</template>
<script>
import CoverLetterGenerator from "@/utils/CoverLetterGenerator";
import SubscriptionHelper from "@/utils/SubscriptionHelper";
// import AnalyticsHelper from "@/utils/AnalyticsHelper";
import {Analytics} from "@aws-amplify/analytics";
import {Auth} from "@aws-amplify/auth";
import {Cache} from "aws-amplify";
import {v4 as uuidv4} from 'uuid';
import CLDownloader from "@/utils/CLDownloader";


export default {
  name: "CoverLetterUserInputs",
  data() {
    return {
      form: {
        companyName: "",
        userBio: "",
        jobDescription: "",
        rememberBio: false,
      },
      loader: null,
        email: null,
        userCV: null,
        extractedText: '',
    };
  },
  async mounted() {
    this.form.userBio = Cache.getItem("userBio");
    if (this.form.userBio) this.form.rememberBio = true;
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      let currentUser = await Auth.currentUserInfo()
        if (currentUser.attributes !== undefined) {
            this.email = currentUser.attributes.email
        } else {
            this.email = currentUser.email
        }
        try {
            let requestGuid = uuidv4()
            // check if user has a subscription
            let usageStatus = await SubscriptionHelper.checkUsageStatus(this.email)

            if (!usageStatus) {
              console.log('user has exceeded the free tier')
              this.showSubscriptionPrompt()
              return false
            }
            this.loader = this.$loading.show({
              // Optional parameters
              container: this.$refs.coverLetterArea,
              canCancel: false,
              onCancel: this.onCancel,
           });
        this.coverLetter = await CoverLetterGenerator.awsBedrockGenerateCoverLetter({
          userBio: this.form.userBio,
          jobDescription: this.form.jobDescription,
            guid: requestGuid,
            ownerEmail: this.email
        });
        this.$root.$emit("show-cover-letter", this.coverLetter);
        this.loader.hide();
        this.$root.$emit("show-nps-survey");
      } catch (e) {
        console.log(e);
        this.loader.hide();
      }
      if (this.form.rememberBio) {
        Cache.setItem("userBio", this.form.userBio);
      }
      await Analytics.record({
          name: "generateCoverLetter",
          attributes: {
              email: this.email
          }
      });
    },
    onReset(event) {
      event.preventDefault();
      this.form.rememberBio = "";
      var checkbox = document.getElementById("checkbox-1");
      if (!checkbox.checked) {
        this.form.userBio = "";
      }
      this.form.jobDescription = "";
      Analytics.record(
          { name: "resetCoverLetterForm", attributes: {
                  email: this.email
              } });
    },
    onEditFormInput(inputType) {
      Analytics.record({
        name: "editUserInput",
        attributes: { inputType, email: this.email },
      });
    },
    onRememberUserBio(rememberBio) {
      if (rememberBio) {
        Analytics.record({ name: "rememberUserBio", attributes: { email: this.email } });
        Cache.setItem("userBio", this.form.userBio);
      } else {
        Analytics.record({ name: "forgetUserBio", attributes: { email: this.email } });
        Cache.removeItem("userBio");
      }
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
    onCVUpload() {
      console.log(this.userCV)
        const reader = new FileReader();
        reader.onload = async () => {
            const dataUrl = reader.result;
            // set loading to true before extracting text from the PDF
            this.loading = true;
            this.form.userBio = await CLDownloader.getCVTextFromPdf(dataUrl);
            console.log(this.extractedText)
            // set loading to false when the text extraction is complete
            this.loading = false;
        };
        reader.readAsDataURL(this.userCV);
    },
    showSubscriptionPrompt() {
      this.$bvModal.msgBoxConfirm(`Click Continue to proceed with our affordable subscription plans.`, {
        title: 'Free Tier Quota Exceeded',
        size: 'sm',
        // buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'Continue',
        cancelVariant: 'outline-secondary',
        centered: true,
        hideHeaderClose: true,
      })
        .then(value => {
          Analytics.record({
            name: "SubscriptionIntentPrompt",
            attributes: {
                status: value
            }
          });
          if (value) {
            this.$router.push({name: 'Subscriptions'})
          }
        })
    },
  },
};
</script>

<style scoped>
.button {
  border: none;
}
.buttonSuccess {
  background-color: #9e32a8;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Browse';
}
</style>
