import data from "@/data";

const SubscriptionHelper = {

    async checkUsageStatus(subscriber) {
        let subInfo = await data.getUserSubscriptionInfo(subscriber)
        let userData = subInfo.data.listSubcriptionss.items
        let subcriptionTier = null
        let counter = 0
        // if not data, create free tier for user
        console.log(userData)
        if (userData.length === 0) {
            await data.addSubscription({
                ownerEmail: subscriber,
                subcriptionTier: 'free',
                counter: counter
            })
            subcriptionTier = 'free'
        } else {
            subcriptionTier = userData[0].tier
            counter = userData[0].usageCounter
        }

        const checkCondition = (subcriptionTier === 'free' && counter >= 3)
        return (checkCondition ? false : true)
    },

    // write a function to create subscription for user (free, basic, gold)
    async createNewSubscription(subscriber, tier) {
        let subInfo = await data.getUserSubscriptionInfo(subscriber)
        let userData = subInfo.data.listSubcriptionss.items
        if (userData.length === 0) {
            await data.addSubscription({
                ownerEmail: subscriber,
                subcriptionTier: tier,
                counter: 0
            })
        } else {
            // update subscription tier
            let userInfo = {
                id: userData[0].id, 
                user: userData[0].user,
                tier: tier,
                usageCounter: userData[0].usageCounter
            }
            await data.updateUserSubscription(userInfo)
        }
    },

    async incrementUsageCounter(subscriber) {
        // write a function to increment usage counter  
        let subInfo = await data.getUserSubscriptionInfo(subscriber)
        let userData = subInfo.data.listSubcriptionss.items[0]
        let counterInfo = {
            id: userData.id, 
            user: userData.user,
            tier: userData.tier,
            usageCounter: userData.usageCounter + 1
        }
        // update usage counter in database
        return await data.updateUserSubscription(counterInfo)
    },

}

export default SubscriptionHelper