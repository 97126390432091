/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPrompt = /* GraphQL */ `
  query GetPrompt($id: ID!) {
    getPrompt(id: $id) {
      id
      userBio
      jobDescription
      ownerEmail
      guid
      ownerId
      owner
      timestamp
      generatedCoverLetter
      createdAt
      updatedAt
    }
  }
`;
export const listPrompts = /* GraphQL */ `
  query ListPrompts(
    $filter: ModelPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrompts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userBio
        jobDescription
        ownerEmail
        guid
        ownerId
        owner
        timestamp
        generatedCoverLetter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubcriptions = /* GraphQL */ `
  query GetSubcriptions($id: ID!) {
    getSubcriptions(id: $id) {
      id
      user
      tier
      usageCounter
      trID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSubcriptionss = /* GraphQL */ `
  query ListSubcriptionss(
    $filter: ModelSubcriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubcriptionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        tier
        usageCounter
        trID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
