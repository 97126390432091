<template>
    <footer class="mt-5">
        <hr data-v-25217279="">
        <b-container>
            <b-row class="justify-content-md-center">
                <b-col col lg="2">
                    <b-link to="privacy">Privacy Policy</b-link>
                </b-col>
                <b-col col lg="2">
                    <b-link to="privacy/user-requests">Data Subject Requests</b-link>
                </b-col>
                <b-col col lg="2">
                    <b-link to="tos">Terms of Service</b-link>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>

<style scoped>

</style>
