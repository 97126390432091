<template>
    <b-container>
        <b-row>
            <b-col v-if="paymentStatus === 'success'">
                <h2>Subscription Successful!</h2>
                <b-card>
                    <p>Your have successfully subscribed to Brecht AI {{ subscriptionTier }} plan! Enjoy unlimited features available in this plan.</p>
                    <p>Expect an email with your receipt shortly. You will be redirected to the Brecht AI app in {{ secondsCountDown }} </p>
                </b-card>
            </b-col>
            <b-col v-else>
                <h2>Oh no 😢!!</h2>
                <b-card>
                    <h4>Your subscription to Brecht AI {{ subscriptionTier }} plan was unsuccessful!</h4>
                    <p>Please try again later. Feel free to continue with the our Free tier.</p>
                    <p>You will be redirected to the Brecht AI app in {{ secondsCountDown }}.</p>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { Analytics } from "@aws-amplify/analytics";
import {Auth} from "@aws-amplify/auth";
import SubscriptionHelper from "@/utils/SubscriptionHelper";
export default {
    name: "PaymentPage",
    async mounted(){
        this.paymentStatus = this.$route.params.payment_status
        this.subscriptionTier = this.capitalizeFirstLetter(this.$route.params.subscription_tier)
        Analytics.record({
            name: 'SubscriptionStatus',
            attributes: {
                status: this.paymentStatus,
                plan: this.subscriptionTier
            },
        })
        if (this.paymentStatus === 'success') {
            this.saveSubscription(this.$route.params.subscription_tier)
        }
        this.countDownTimer()
    },
    data () {
        return {
            subscriptionTier: null,
            paymentStatus: null,
            secondsCountDown: 7,
            email: null
        }
    },
    methods: {
        countDownTimer () {
            if (this.secondsCountDown > 0) {
                setTimeout(() => {
                    this.secondsCountDown -= 1
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$router.push({name: 'App'})
            }
        },
        async saveSubscription (tier) {
            // send to backend API for tracking purposes.
            let currentUser = await Auth.currentUserInfo()
            if (currentUser.attributes !== undefined) {
                this.email = currentUser.attributes.email
            } else {
                this.email = currentUser.email
            }
            await SubscriptionHelper.createNewSubscription(this.email, tier)
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    },
}
</script>

<style scoped>

</style>
