<template>
    <div>
        <amplify-authenticator username-alias="email">
            <amplify-sign-in
                header-text="Sign in to Brecht AI"
                slot="sign-in"
                :style="{display: 'flex',justifyContent: 'center'}"
                username-alias="email"
            >
                <div slot="federated-buttons">
<!--                    <amplify-google-button-->
<!--                        client-id="704658354423-enuh1a8hf7pudl9gmkdnac6asu4n8mju.apps.googleusercontent.com"-->
<!--                        :handleError="myAlert"-->
<!--                    />-->
                </div>
            </amplify-sign-in>
            <amplify-sign-up
                header-text="Sign Up for Brecht AI"
                slot="sign-up"
                username-alias="email"
                :formFields.prop="formFields"
                :style="{display: 'flex',justifyContent: 'center'}"
            />
        </amplify-authenticator>
    </div>
</template>

<script>
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'
import { mapActions } from "vuex";


export default {
    name: "SignInWithEmailPage",
    created() {
        onAuthUIStateChange((authState, authData) => {
            this.authState = authState;
            if (authState === AuthState.SignedIn) {
                this.user = authData.attributes
                this.login(this.user)
                this.$router.push({name: 'App'})
            }
        })
    },
    data(){
        return {
            user: undefined,
            authState: undefined,
            formFields: [
                {
                    type: 'email',
                    label: 'Email Address *',
                    placeholder: 'Enter your email address:',
                    inputProps: { required: true, autocomplete: 'username' },
                },
                {
                    type: 'password',
                    label: 'Password *',
                    placeholder: 'Enter your password',
                    inputProps: { required: true, autocomplete: 'new-password' },
                },
                // {
                //     type: 'checkbox',
                //     label: 'I accept the terms of service and the pivacy policy',
                //     inputProps: { required: true, type: 'checkbox' },
                //     // Set the attribute name to the name of your custom attribute in the AWS console
                //     custom: { required: true, attributeName: 'custom:terms_of_service' }
                // }
            ]
        }
    },
    beforeDestroy() {
        return onAuthUIStateChange;
    },
    methods: {
        ...mapActions(["login", "logout"]),
        myAlert(event){
            console.log(event)
        }
    }
}
</script>

<style>
.h3 {
    text-align: center !important;
}
</style>
