import Vuex from 'vuex'
import Vue from 'vue'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
    key: 'vuex', // The key to store the state on in the storage provider.
    storage: window.localStorage // or window.sessionStorage or localForage
    // Function that passes the state and returns the state with only the objects you want to store.
    // reducer: state => state,
    // Function that passes a mutation and lets you decide if it should update the state in localStorage.
    // filter: mutation => (true)
})

// A list of states in the store
const userState = {
    profile: null,
    sellerAccountProfile: null,
    isLoggedIn: false,
    isSellerAccount: false
}

const userMutations = {
    /**
     * @param {*} state
     * @param {*} profile
     */
    setProfile (state, profile) {
        state.profile = profile
    },
    setLoginStatus (state, status) {
        state.isLoggedIn = status
    },
    setSellerAccountStatus (state, status) {
        state.isSellerAccount = status
    },
    setSellerAccountProfile (state, sellerAccountProfile) {
        state.sellerAccountProfile = sellerAccountProfile
        state.isSellerAccount = true
    }
}

/**
 * Actions can achieve async operations, hence the process of fetching
 * will be done in action asynchronously
 */
const userActions = {
    logout (context) {
        context.commit('setLoginStatus', false)
        context.commit('setProfile', null)
    },
    login (context, profile) {
        context.commit('setLoginStatus', true)
        context.commit('setProfile', profile)
    },
}

const userGetters = {
    profile: userState => userState.profile,
    isLoggedIn: userState => userState.isLoggedIn
}

const userModule = {
    state: userState,
    mutations: userMutations,
    actions: userActions,
    getters: userGetters
}

const store = new Vuex.Store({
    modules: {
        user: userModule
    },
    plugins: [vuexLocalStorage.plugin]
})

export default store
