<template>
    <span>
        <b-button variant="outline-primary" class="mb-2" @click="signIn" id="facebook-login-btn" >
            <b-icon-facebook></b-icon-facebook> Sign in with Facebook
        </b-button>
    </span>

</template>

<script>
import { mapActions } from "vuex";
import { Auth } from 'aws-amplify';
export default {
    name: "SignInWithFacebook",
    data() {
        return {
            user: null,
            client: null
        }
    },
    beforeMount() {
    },
    methods: {
        ...mapActions(["login", "logout"]),
        redirect: function() {
            this.$router.push({name: 'App'});
        },
        async signIn() {
            const fb = window.FB;
            const loginStatus = fb.getLoginStatus()
            if (loginStatus === 'connected') {
                console.log('fully logged in')
            } else {
                fb.login(
                    response => {
                        if (!response || !response.authResponse) {
                            return;
                        }
                    },
                    {
                        scope: 'public_profile,email'
                    }
                )
            }
        },
        getAWSCredentials(response) {
            const { accessToken, expiresIn } = response;
            const date = new Date();
            const expires_at = expiresIn * 1000 + date.getTime();
            if (!accessToken) {
                return;
            }
            const fb = window.FB;
            fb.api('/me', { fields: 'name,email' }, response => {
                const user = {
                    name: response.name,
                    email: response.email
                };

                Auth.federatedSignIn('facebook', { token: accessToken, expires_at }, user)
                    .then(credentials => {
                        console.log(credentials);
                    });
                this.login(user)
                this.redirect()
            });
        }
    }
}
</script>

<style scoped>

</style>
