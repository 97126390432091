import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/pages/HomePage'
import LandingPage from '@/pages/LandingPage'
import LoginPage from '@/pages/LoginPage'
import PrivacyPage from '@/pages/PrivacyPage'
import PricingPage from '@/pages/PricingPage'
import SignInWithEmailPage from '@/pages/SignInWithEmailPage'
import PaymentPage from '@/pages/PaymentPage'
import GDPRRequestPage from '@/pages/GDPRRequestPage'
import TermsOfServicePage from '@/pages/TermsOfServicePage'
import { Auth } from 'aws-amplify';

const routes = [
    {
        path: '/',
        name: 'Landing',
        component: LandingPage
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/login/email',
        name: 'EmailLogin',
        component: SignInWithEmailPage
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: PrivacyPage
    },
    {
        path: '/privacy/user-requests',
        name: 'PrivacyUserRequestForm',
        component: GDPRRequestPage
    },
    {
        path:'/tos',
        name:'TermsOfServicePage',
        component: TermsOfServicePage
    },
    {
        path:'/payment/:payment_status/:subscription_tier',
        name:'PaymentPage',
        component: PaymentPage,
        meta: { requiresAuth: true }
    },
    {
        path: '/app',
        name: 'App',
        component: HomePage,
        meta: { requiresAuth: true }
    },
    {
        path: '/subscriptions',
        name: 'Subscriptions',
        component: PricingPage,
        meta: { requiresAuth: true }
    },
    { path: '*', redirect: '/' }// catch all use case
]

Vue.use(VueRouter)

const router = new VueRouter({
    routes: routes,
    mode: 'history',
    hash: false,
})

/* eslint-disable no-unused-vars */
router.beforeResolve((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        let user;
        Auth.currentAuthenticatedUser()
            .then((data) => {
                if (data && data.signInUserSession) {
                    user = data;
                }
                next();
            })
            .catch((e) => {
                next({
                    path: "/login",
                });
            });
    }
    next();
});
/* eslint-enable no-unused-vars */


export default router
