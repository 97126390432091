<template>
    <b-container>
        <b-row>
            <b-col>
                <h1 class="display-4">Brecht AI Pricing Plans</h1>
            </b-col>
        </b-row>
        <SubscriptionsComponent/>
    </b-container>
</template>

<script>
import SubscriptionsComponent from "@/components/SubscriptionsComponent";
export default {
    name: "PricingPage",
    components: { SubscriptionsComponent }
}
</script>

<style>

</style>
