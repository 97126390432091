<template>
    <div class="benefits-section">
      <div class="row p-2 p-md-5" style="background-color: whitesmoke;">
        <div class="col-12">
          <h1>Transform Your Job Application Using Cover Letter Genius</h1>
  
      <div class="row justify-content-center" style="margin-top: 2em;">
        <div class="col-12 col-md-4">
          <div class="card text-center">
            <div class="card-body">
              <i class="bi bi-clock fs-1 mb-4"></i>
              <h5 class="card-title mb-3">Save Time and Effort</h5>
              <p class="card-text">Save hours of time and effort in crafting your cover letters. Our AI algorithm generates personalized cover letters that match the job description and user's skills, making the application process quicker and more efficient.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card text-center">
            <div class="card-body">
              <i class="bi bi-trophy fs-1 mb-4"></i>
              <h5 class="card-title mb-3">Stand Out from the Crowd</h5>
              <p class="card-text">Create tailored and unique cover letters that make you stand out in a crowded job market. Our AI technology analyzes the job requirements and your qualifications to create a personalized cover letter that showcases your skills and experiences.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card text-center">
            <div class="card-body">
              <i class="bi bi-bullseye fs-1 mb-4"></i>
              <h5 class="card-title mb-3">Get More Interviews</h5>
              <p class="card-text">Get well-written cover letters that are error-free, professional, and follow best practices. With our AI algorithm, users can create a cover letter that captures the attention of recruiters and hiring managers, increasing their chances of getting hired.</p>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BenefitsSection',
  };
  </script>
  
  <style scoped>
  .card {
    border: none;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin-bottom: 1rem;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  
  .card-body {
    padding: 2rem;
  }
  
  .card-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .card-text {
    font-size: 1rem;
  }
  </style>
  