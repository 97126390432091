<template>
    <!-- Carousel wrapper -->
    <span>
        <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="8000"
            controls
            indicators
            img-width="1024"
            img-height="480"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
        >
        <b-carousel-slide v-for="(testimonial, index) in testimonials" :key="index">
            <template #img>
                <customer-testimonial
                    :img="testimonial.img"
                    :testimonial="testimonial.testimonial"
                    :name="testimonial.name"
                />
            </template>
        </b-carousel-slide>

    </b-carousel>
    </span>
    <!-- Carousel wrapper -->
</template>

<script>
import CustomerTestimonial from '@/components/CustomerTestimonial'
export default {
    name: "TestimonialSlider",
    components: {CustomerTestimonial},
    data() {
        return {
            slide: 0,
            sliding: null,
            testimonials: [
                {
                    name: 'Hau Jon C.',
                    testimonial: 'As a job seeker, I have always struggled with writing personalized cover letters that stand out from the competition. But with the app, I was able to create unique, tailored cover letters for each job I applied for in just seconds',
                    img: require('../assets/img/haujon.png')
                },
                {
                    name: 'Mirian G.',
                    testimonial: 'The technology is amazing. It took the guesswork out of writing cover letters and ensured that my applications would stand out. I am blown away by the professionalism and engagement of the cover letters generated.',
                    img: require('../assets/img/mirianG.png')
                },
                {
                    name: 'Trevor O.',
                    testimonial: ' I am confident that these personalized cover letters have helped me land interviews and get my foot in the door with potential employers.',
                    img: require('../assets/img/trevorO.png')
                },
            ]
        }
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        }
    }
}
</script>

<style scoped>

</style>
