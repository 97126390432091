<template>
  <div class="landingPage m-4 m-md-5">
    <div class="overview row p-2 p-md-5 align-items-start">
      <div class="col-12 col-lg-6 text-start d-flex flex-column justify-content-center">
        <h1 class="mt-16 text-xl fs-1">
          Impress Employers with a Personal and Job-Specific Cover Letter That Stands Out
        </h1>
        <p>Make a lasting impression with a personalized cover letter that highlights your unique skills and experience.</p>
        <p>With Brecht AI's Cover Letter Genius, you can easily create a job-specific cover letter in just seconds.</p> 
        <p>Our AI-powered technology ensures that your cover letter is well-written, error-free, and professional.</p>
        <div class="text-start text-black">
          <b-button
            size="lg"
            href="/login"
            class="button buttonSuccess btn-secondary"
            variant="success"
          >
            Create Your Free Cover Letter Now
          </b-button>
        </div>
      </div>
      <div class="col-12 col-lg-6 order-lg-2 d-flex align-items-center">
        <img src="@/assets/logo.png" alt="Landing Page" class="heroImage" />
      </div>
    </div>
    <BenefitsSection />

    <div class="row justify-content-center" style="margin-top: 2em">
      <h3 class="mb-5">Here's what users have to say!</h3>
      <testimonial-slider></testimonial-slider>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import { Analytics } from "@aws-amplify/analytics";
import TestimonialSlider from "@/components/TestimonialSlider";
import FooterComponent from "@/components/FooterComponent";
import BenefitsSection from "@/components/BenefitsSection";


export default {
  name: "LandingPage",
  props: {
    msg: String,
  },
  components: { TestimonialSlider, FooterComponent, BenefitsSection },
  mounted() {
    this.startTime = new Date();
  },
  data() {
    return {
      form: {
        email: "",
      },
      startTime: null,
      items: [
        "Make a lasting impression on potential employers with a personalized cover letter that highlights your unique skills and experience. With Brecht AI's Cover Letter Genius, you can easily create a job-specific cover letter in just seconds. Our AI-powered technology ensures that your cover letter is well-written, error-free, and professional. Plus, it's completely free to get started! Click the button below to get started and land your dream job today."
      ],
    };
  },
  methods: {
    async onSubmit() {
      let signUpTime = new Date();
      let timeToSignUp = (signUpTime - this.startTime) / 1000;
      await Analytics.record({
        name: "waitListSignUp",
        attributes: {
          userEmail: this.form.email,
        },
        metrics: { timeToSignUpSeconds: timeToSignUp },
      });
    },
  },
};
</script>

<style scoped>
.landingPage {
  height: 90vh;
}

.heroImage {
  width: 85%;
}

.overview {
  background-color: lightgray;
  border-radius: 16px;
}
.button {
  border: none;
}
.buttonSuccess {
  background-color: #9e32a8;
}
.btn-success:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.list-group {
  background-color: lightgray;
  border-radius: 16px;
  padding: 1rem;
  list-style-type: disc;
}

.list-group-item {
  background-color: transparent;
  border: none;

}


@media (min-width: 768px) {
  .my-component {
    font-size: 1.5rem;
  }
}
</style>
