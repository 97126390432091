import {API} from '@aws-amplify/api'
import {Auth} from "@aws-amplify/auth";
import data from "@/data";
import SubscriptionHelper from "@/utils/SubscriptionHelper";
import { BedrockRuntimeClient, InvokeModelCommand } from "@aws-sdk/client-bedrock-runtime"; 

let apiName = 'coverlettergenapi'
let apiPath = '/api/v1'
let openAIInferencePath = `${apiPath}/openai`

const CoverLetterGenerator = {

    async generateCoverLetterApi(params) {
        let apiInit = {
            headers: {},
            response: true,
            body : params
        }
        let response = await API.post(apiName, openAIInferencePath, apiInit)
        await SubscriptionHelper.incrementUsageCounter(params['ownerEmail'])
        let coverLetter = response.data.message
        params['generatedCoverLetter'] = coverLetter
        await data.saveUserPrompt(params)
        return coverLetter
    },

    async awsBedrockGenerateCoverLetter(params) {
        let credentials = await Auth.currentCredentials()
        let inputText = generatePrompt(params)
        const client = new BedrockRuntimeClient({
            credentials: credentials,
            region: 'us-east-1',
        });
        let promptPayload = {
            "inputText": inputText,
            "textGenerationConfig": {
                maxTokenCount: 4096,
                stopSequences: [],
                temperature: 1.0,
                topP: 0.9
            }
        }
        const command = new InvokeModelCommand({
            modelId: "amazon.titan-tg1-large",
            contentType: "application/json",
            body: JSON.stringify(promptPayload)
        });
        const response = await client.send(command);
        let rawResponse = new TextDecoder().decode(response.body);
        const parsedResponse = JSON.parse(rawResponse)
        let coverLetter = parsedResponse.results[0].outputText
        params['generatedCoverLetter'] = coverLetter
        await data.saveUserPrompt(params)
        return coverLetter
    }
}

function generatePrompt(params) {
    return `Given the following bio about myself: ${params.userBio}
        and the following job description: ${params.jobDescription}
        Write a personalized cover letter for me to apply to this position.
        Start with a strong, attention-grabbing beginning.
        In the middle connect the dots between the job description and the bio.
        Finish with an enthusiastic ending, ideally connecting back to the start.
        Don't just repeat the bio.`
}

export default  CoverLetterGenerator
